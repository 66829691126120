<template>
  <v-app-bar
    v-if="auth"
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <v-text-field
      v-if="$route.path !== '/search-table'"
      v-model="appBarSearch"
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
      @input="$router.push({query: {search: appBarSearch}})"
      @keypress.enter="setSearch"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
          @click.native="setSearch()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/pages/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      appBarSearch: '',
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),

    computed: {
      ...mapState(['drawer', 'auth']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      setSearch () {
        this.$router.push({ path: '/search-table', query: { search: this.appBarSearch } })
      },
    },
  }
</script>
